import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { CustomHomepage } from '../common/core/pages/custom-homepage.service';
import { Settings } from '../common/core/config/settings.service';
import { AppHttpClient } from '../common/core/http/app-http-client.service';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Select } from '@ngxs/store';
import { AppState } from './state/app-state';
import { Observable } from 'rxjs';
import { PageTitleService } from './page-title.service';
var AppComponent = /** @class */ (function () {
    function AppComponent(customHomepage, settings, httpClient, router, pageTitle) {
        this.customHomepage = customHomepage;
        this.settings = settings;
        this.httpClient = httpClient;
        this.router = router;
        this.pageTitle = pageTitle;
    }
    AppComponent.prototype.ngOnInit = function () {
        this.customHomepage.select();
        this.pageTitle.init();
        this.settings.setHttpClient(this.httpClient);
        // google analytics
        if (this.settings.get('analytics.tracking_code')) {
            this.triggerAnalyticsPageView();
        }
    };
    AppComponent.prototype.triggerAnalyticsPageView = function () {
        this.router.events
            .pipe(filter(function (e) { return e instanceof NavigationEnd; }))
            .subscribe(function (event) {
            if (!window['ga'])
                return;
            window['ga']('set', 'page', event.urlAfterRedirects);
            window['ga']('send', 'pageview');
        });
    };
    tslib_1.__decorate([
        Select(AppState.loading),
        tslib_1.__metadata("design:type", Observable)
    ], AppComponent.prototype, "loading$", void 0);
    return AppComponent;
}());
export { AppComponent };
