import * as tslib_1 from "tslib";
import { Select, Store } from '@ngxs/store';
import { HomepageState } from '../state/homepage-state';
import { Observable } from 'rxjs';
import { ChangeSlide } from '../state/homepage-state.actions';
import { TitleUrlsService } from '../../titles/title-urls.service';
import { MEDIA_TYPE } from '../../media-type';
import { PlayVideo } from '../../player/state/player-state-actions';
import { BreakpointsService } from '../../../../common/core/ui/breakpoints.service';
var SliderComponent = /** @class */ (function () {
    function SliderComponent(store, urls, breakpoints) {
        this.store = store;
        this.urls = urls;
        this.breakpoints = breakpoints;
    }
    SliderComponent.prototype.changeSlide = function (index) {
        var active = this.store.selectSnapshot(HomepageState.activeSlide);
        if (index === 'next') {
            index = active + 1;
        }
        else if (index === 'previous') {
            index = active - 1;
        }
        this.store.dispatch(new ChangeSlide(index));
    };
    SliderComponent.prototype.filterTitles = function (items) {
        return items.filter(function (item) {
            return item.type === MEDIA_TYPE.TITLE;
        });
    };
    SliderComponent.prototype.playVideo = function (title) {
        this.store.dispatch(new PlayVideo(title.videos[0], title));
    };
    tslib_1.__decorate([
        Select(HomepageState.sliderList),
        tslib_1.__metadata("design:type", Observable)
    ], SliderComponent.prototype, "sliderList$", void 0);
    tslib_1.__decorate([
        Select(HomepageState.activeSlide),
        tslib_1.__metadata("design:type", Observable)
    ], SliderComponent.prototype, "activeSlide$", void 0);
    return SliderComponent;
}());
export { SliderComponent };
