import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import * as i0 from "@angular/core";
import * as i1 from "@angular/cdk/layout";
var BreakpointsService = /** @class */ (function () {
    function BreakpointsService(breakpointObserver) {
        var _this = this;
        this.breakpointObserver = breakpointObserver;
        this.isMobile = false;
        this.isTablet = false;
        this.breakpointObserver.observe(Breakpoints.Handset).subscribe(function (result) {
            _this.isMobile = result.matches;
        });
        this.breakpointObserver.observe(Breakpoints.Tablet).subscribe(function (result) {
            _this.isTablet = result.matches;
        });
    }
    BreakpointsService.prototype.observe = function (value) {
        return this.breakpointObserver.observe(value);
    };
    BreakpointsService.ngInjectableDef = i0.defineInjectable({ factory: function BreakpointsService_Factory() { return new BreakpointsService(i0.inject(i1.BreakpointObserver)); }, token: BreakpointsService, providedIn: "root" });
    return BreakpointsService;
}());
export { BreakpointsService };
