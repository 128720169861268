import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { PeopleService } from '../people.service';
import { InfiniteScroll } from '../../../../common/core/ui/infinite-scroll/infinite.scroll';
import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { TitleUrlsService } from '../../titles/title-urls.service';
var PeopleIndexComponent = /** @class */ (function (_super) {
    tslib_1.__extends(PeopleIndexComponent, _super);
    function PeopleIndexComponent(people, urls) {
        var _this = _super.call(this) || this;
        _this.people = people;
        _this.urls = urls;
        _this.loading$ = new BehaviorSubject(false);
        _this.people$ = new BehaviorSubject(null);
        return _this;
    }
    PeopleIndexComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
        this.loadPeople();
    };
    PeopleIndexComponent.prototype.loadPeople = function () {
        var _this = this;
        this.loading$.next(true);
        var page = this.people$.value ? (this.people$.value.current_page + 1) : 1;
        this.people.getAll({ perPage: 10, page: page, mostPopular: true })
            .pipe(finalize(function () { return _this.loading$.next(false); }))
            .subscribe(function (response) {
            if (_this.people$.value) {
                response.data = _this.people$.value.data.concat(response.data);
            }
            _this.people$.next(response);
        });
    };
    PeopleIndexComponent.prototype.loadMoreItems = function () {
        this.loadPeople();
    };
    PeopleIndexComponent.prototype.canLoadMore = function () {
        return !this.isLoading() && this.people$.value.current_page < this.people$.value.last_page;
    };
    PeopleIndexComponent.prototype.isLoading = function () {
        return this.loading$.value;
    };
    return PeopleIndexComponent;
}(InfiniteScroll));
export { PeopleIndexComponent };
