import * as tslib_1 from "tslib";
import { Action, Selector, State, Store } from '@ngxs/store';
import { RouterState } from '@ngxs/router-plugin';
import { tap } from 'rxjs/operators';
import { ToggleGlobalLoader } from '../../../state/app-state-actions';
import { LoadPerson, SetPerson } from './person-state-actions';
import { PeopleService } from '../people.service';
var PersonState = /** @class */ (function () {
    function PersonState(store, people) {
        this.store = store;
        this.people = people;
    }
    PersonState.person = function (state) {
        return state.person;
    };
    PersonState.credits = function (state) {
        return state.credits;
    };
    PersonState.creditsCount = function (state) {
        return Object.keys(state.credits).map(function (department) {
            return state.credits[department].length;
        }).reduce(function (a, b) { return a + b; }, 0);
    };
    PersonState.knownFor = function (state) {
        return state.knownFor;
    };
    PersonState.backdrop = function (state) {
        var titleWithBackdrop = state.knownFor.find(function (title) { return !!title.backdrop; });
        return titleWithBackdrop ? titleWithBackdrop.backdrop : null;
    };
    PersonState.prototype.loadPerson = function (ctx) {
        var _this = this;
        var state = ctx.getState(), params = this.store.selectSnapshot(RouterState.state).root.firstChild.firstChild.params;
        if (state.person && state.person.id === +params.id)
            return;
        return this.people.get(params.id).pipe(tap(function (response) {
            _this.store.dispatch(new SetPerson(response));
        }));
    };
    PersonState.prototype.setTitle = function (ctx, action) {
        ctx.patchState({
            person: action.response.person,
            credits: action.response.credits,
            knownFor: action.response.knownFor,
        });
        this.store.dispatch(new ToggleGlobalLoader(false));
    };
    tslib_1.__decorate([
        Action(LoadPerson),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], PersonState.prototype, "loadPerson", null);
    tslib_1.__decorate([
        Action(SetPerson),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, SetPerson]),
        tslib_1.__metadata("design:returntype", void 0)
    ], PersonState.prototype, "setTitle", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], PersonState, "person", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], PersonState, "credits", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", Number)
    ], PersonState, "creditsCount", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], PersonState, "knownFor", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], PersonState, "backdrop", null);
    PersonState = tslib_1.__decorate([
        State({
            name: 'person',
            defaults: {
                knownFor: [],
            }
        }),
        tslib_1.__metadata("design:paramtypes", [Store,
            PeopleService])
    ], PersonState);
    return PersonState;
}());
export { PersonState };
