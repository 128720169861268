<form class="auth-page" id="auth-page" (ngSubmit)="sendPasswordResetLink()" ngNativeValidate>
    <div class="auth-panel">
        <loading-indicator class="overlay" [isVisible]="isLoading"></loading-indicator>

        <a class="logo" [routerLink]="['/']"><img [src]="settings.getBaseUrl(true) + settings.get('branding.logo_dark')" alt="logo"></a>

        <div class="general-error" *ngIf="errors.general">{{errors.general}}</div>

        <div class="page-header">
            <div class="big-text" trans>Forgot Password?</div>
            <div class="small-text" trans>Enter your email address below and we will send you a link to reset it.</div>
        </div>

        <div class="page-content">
            <div class="input-container">
                <label for="email" class="hidden"></label>
                <input type="email" [(ngModel)]="model.email" name="email" id="email" required placeholder="Email" trans-placeholder>
                <p class="error email-error" *ngIf="errors.email">{{errors.email}}</p>
            </div>

            <div class="back-and-reset-row">
                <a class="button cancel back-button" mat-button [routerLink]="['/login']" trans>Back</a>
                <button class="button primary submit-button" mat-raised-button [color]="settings.get('vebto.auth.color')" type="submit" trans>Send</button>
            </div>
        </div>
    </div>
</form>