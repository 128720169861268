import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { PlayerState } from '../state/player-state';
import { BehaviorSubject, Observable } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { youtubePlayerUrlParams } from '../youtube-player-params';
import { OverlayPanelRef } from '../../../../common/core/ui/overlay-panel/overlay-panel-ref';
import { PlayerOverlayClosed, PlayerOverlayOpened, PlayVideo, ToggleSidebar } from '../state/player-state-actions';
import { BreakpointsService } from '../../../../common/core/ui/breakpoints.service';
var PlayerComponent = /** @class */ (function () {
    function PlayerComponent(store, sanitizer, overlayRef, breakpoints) {
        this.store = store;
        this.sanitizer = sanitizer;
        this.overlayRef = overlayRef;
        this.breakpoints = breakpoints;
        this.safeUrl$ = new BehaviorSubject(null);
        this.minimized = false;
    }
    PlayerComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.store.dispatch(new PlayerOverlayOpened());
        this.activeVideo$.subscribe(function (video) {
            _this.safeUrl$.next(_this.sanitizer.bypassSecurityTrustResourceUrl(video.url + youtubePlayerUrlParams()));
        });
        // hide sidebar on mobile
        if (this.breakpoints.isMobile) {
            this.toggleSidebar();
        }
    };
    PlayerComponent.prototype.ngOnDestroy = function () {
        this.store.dispatch(new PlayerOverlayClosed());
    };
    PlayerComponent.prototype.playVideo = function (video) {
        this.store.dispatch(new PlayVideo(video, video.title));
    };
    PlayerComponent.prototype.toggleSidebar = function () {
        this.store.dispatch(new ToggleSidebar());
    };
    PlayerComponent.prototype.close = function () {
        this.overlayRef.close();
    };
    tslib_1.__decorate([
        Select(PlayerState.activeVideo),
        tslib_1.__metadata("design:type", Observable)
    ], PlayerComponent.prototype, "activeVideo$", void 0);
    tslib_1.__decorate([
        Select(PlayerState.relatedVideos),
        tslib_1.__metadata("design:type", Observable)
    ], PlayerComponent.prototype, "relatedVideos$", void 0);
    tslib_1.__decorate([
        Select(PlayerState.mediaItem),
        tslib_1.__metadata("design:type", Observable)
    ], PlayerComponent.prototype, "mediaItem$", void 0);
    tslib_1.__decorate([
        Select(PlayerState.sidebarOpen),
        tslib_1.__metadata("design:type", Observable)
    ], PlayerComponent.prototype, "sidebarOpen$", void 0);
    return PlayerComponent;
}());
export { PlayerComponent };
