<form class="auth-page" id="auth-page" (ngSubmit)="resetPassword()">
    <div class="many-inputs auth-panel">
        <loading-indicator class="overlay" [isVisible]="isLoading"></loading-indicator>

        <a class="logo" [routerLink]="['/']"><img [src]="settings.get('branding.logo_dark')" alt="logo"></a>

        <div class="input-container">
            <label for="email" class="hidden" trans>Email</label>
            <input type="email" [(ngModel)]="model.email" name="email" id="email" required placeholder="Email" trans-placeholder>
            <p class="error email-error" *ngIf="errors.email">{{errors.email}}</p>
        </div>

        <div class="input-container">
            <label for="password" class="hidden" trans>New Password</label>
            <input type="password" [(ngModel)]="model.password" name="password" id="password" required placeholder="Password" trans-placeholder>
            <p class="error password-error" *ngIf="errors.password">{{errors.password}}</p>
        </div>

        <div class="input-container">
            <label for="password_confirmation" class="hidden" trans>Confirm New Password</label>
            <input type="password" [(ngModel)]="model.password_confirmation" name="password_confirmation" id="password_confirmation" required placeholder="Confirm Password" trans-placeholder>
        </div>

        <div class="right">
            <button mat-raised-button [color]="settings.get('vebto.auth.color')" type="submit" trans>Reset Password</button>
        </div>
    </div>
</form>
