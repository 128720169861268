import * as tslib_1 from "tslib";
import { Action, Selector, State } from '@ngxs/store';
import { ListsService } from '../../lists.service';
import { CurrentUser } from '../../../../../common/auth/current-user';
import { Toast } from '../../../../../common/core/ui/toast.service';
import { MESSAGES } from '../../../../toast-messages';
import { finalize, tap } from 'rxjs/operators';
import { AddRating, AddToWatchlist, ClearUserLists, LoadUserLists, RemoveFromWatchlist, RemoveRating, SetRatings, SetWatchlist } from './user-lists-state-actions';
var UserListsState = /** @class */ (function () {
    function UserListsState(lists, currentUser, toast) {
        this.lists = lists;
        this.currentUser = currentUser;
        this.toast = toast;
    }
    UserListsState.loading = function (state) {
        return state.loading;
    };
    UserListsState.watchlist = function (state) {
        return state.watchlist;
    };
    UserListsState.lists = function (state) {
        return state.lists;
    };
    UserListsState.ratings = function (state) {
        return state.ratings;
    };
    UserListsState.prototype.addToWatchlist = function (ctx, action) {
        var _this = this;
        ctx.patchState({ loading: true });
        var listId = ctx.getState().watchlist.id;
        return this.lists.addItem(listId, action.item).pipe(tap(function () {
            _this.toast.open(MESSAGES.WATCHLIST_ADD_SUCCESS);
            var watchlist = ctx.getState().watchlist;
            watchlist.items.push({ id: action.item.id, type: action.item.type });
            ctx.patchState({ watchlist: tslib_1.__assign({}, watchlist) });
        }), finalize(function () { return ctx.patchState({ loading: false }); }));
    };
    UserListsState.prototype.removeFromWatchlist = function (ctx, action) {
        var _this = this;
        ctx.patchState({ loading: true });
        var listId = ctx.getState().watchlist.id;
        return this.lists.removeItem(listId, action.item).pipe(tap(function () {
            _this.toast.open(MESSAGES.WATCHLIST_REMOVE_SUCCESS);
            var watchlist = ctx.getState().watchlist;
            var i = watchlist.items.findIndex(function (item) { return item.id === action.item.id && item.type === action.item.type; });
            watchlist.items.splice(i, 1);
            ctx.patchState({ watchlist: tslib_1.__assign({}, watchlist) });
        }), finalize(function () { return ctx.patchState({ loading: false }); }));
    };
    UserListsState.prototype.setWatchlist = function (ctx, action) {
        ctx.patchState({ watchlist: action.watchlist });
    };
    UserListsState.prototype.setRatings = function (ctx, action) {
        ctx.patchState({ ratings: action.ratings || [] });
    };
    UserListsState.prototype.addRating = function (ctx, action) {
        var oldRatings = ctx.getState().ratings.slice();
        var index = oldRatings.findIndex(function (r) { return r.id === action.rating.id; });
        if (index > -1) {
            oldRatings[index] = action.rating;
        }
        else {
            oldRatings.push(action.rating);
        }
        ctx.patchState({ ratings: oldRatings });
    };
    UserListsState.prototype.removeRating = function (ctx, action) {
        var newRatings = ctx.getState().ratings.filter(function (rating) {
            return rating.id !== action.rating.id;
        });
        ctx.patchState({ ratings: newRatings });
    };
    UserListsState.prototype.loadUserLists = function (ctx) {
        if (ctx.getState().lists.length)
            return;
        ctx.patchState({ loading: true });
        var params = { userId: this.currentUser.get('id') };
        return this.lists.getAll(params).pipe(tap(function (response) {
            ctx.patchState({ lists: response.data });
        }), finalize(function () { return ctx.patchState({ loading: false }); }));
    };
    UserListsState.prototype.clearUserLists = function (ctx) {
        ctx.patchState({ lists: [] });
    };
    tslib_1.__decorate([
        Action(AddToWatchlist),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, AddToWatchlist]),
        tslib_1.__metadata("design:returntype", void 0)
    ], UserListsState.prototype, "addToWatchlist", null);
    tslib_1.__decorate([
        Action(RemoveFromWatchlist),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, RemoveFromWatchlist]),
        tslib_1.__metadata("design:returntype", void 0)
    ], UserListsState.prototype, "removeFromWatchlist", null);
    tslib_1.__decorate([
        Action(SetWatchlist),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, SetWatchlist]),
        tslib_1.__metadata("design:returntype", void 0)
    ], UserListsState.prototype, "setWatchlist", null);
    tslib_1.__decorate([
        Action(SetRatings),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, SetRatings]),
        tslib_1.__metadata("design:returntype", void 0)
    ], UserListsState.prototype, "setRatings", null);
    tslib_1.__decorate([
        Action(AddRating),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, AddRating]),
        tslib_1.__metadata("design:returntype", void 0)
    ], UserListsState.prototype, "addRating", null);
    tslib_1.__decorate([
        Action(RemoveRating),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, RemoveRating]),
        tslib_1.__metadata("design:returntype", void 0)
    ], UserListsState.prototype, "removeRating", null);
    tslib_1.__decorate([
        Action(LoadUserLists),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], UserListsState.prototype, "loadUserLists", null);
    tslib_1.__decorate([
        Action(ClearUserLists),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], UserListsState.prototype, "clearUserLists", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], UserListsState, "loading", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], UserListsState, "watchlist", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], UserListsState, "lists", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], UserListsState, "ratings", null);
    UserListsState = tslib_1.__decorate([
        State({
            name: 'userLists',
            defaults: {
                loading: false,
                ratings: [],
                lists: [],
            }
        }),
        tslib_1.__metadata("design:paramtypes", [ListsService,
            CurrentUser,
            Toast])
    ], UserListsState);
    return UserListsState;
}());
export { UserListsState };
