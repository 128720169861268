import { Routes } from '@angular/router';
import { ContactComponent } from '../common/contact/contact.component';
import { HomepageComponent } from './site/homepage/homepage.component';
import { HomepageListsResolverService } from './site/homepage/homepage-lists-resolver.service';
var ɵ0 = { name: 'homepage' };
var routes = [
    { path: '', component: HomepageComponent, resolve: { store: HomepageListsResolverService }, data: ɵ0 },
    { path: 'admin', loadChildren: 'app/admin/admin.module#AdminModule' },
    { path: 'billing', loadChildren: 'common/billing/billing.module#BillingModule' },
    { path: 'contact', component: ContactComponent },
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0 };
